import { createRouter, RouteRecordRaw, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";

// Login
const Login = () => import("../views/pages/Login.vue");

const ModifyPassword = () => import("../views/pages/ModifyPassword.vue");
const Register = () => import("../views/pages/Register.vue");
const ForgetPassword = () => import("../views/pages/ForgetPassword.vue");
const ResendVerificationMail = () => import("../views/pages/ResendVerificationMail.vue");
const VerifyEmail = () => import("../views/pages/VerifyEmail.vue");
const ResetPassword = () => import("../views/pages/ResetPassword.vue");

// Index
const Index = () => import("../views/pages/Index.vue");

// 權限管理
const AccountManagement = () => import("../views/role_management/AccountManagement.vue");
const AccountEdit = () => import("../views/role_management/AccountEdit.vue");
const RoleManagement = () => import("../views/role_management/RoleManagement.vue");
const RoleEdit = () => import("../views/role_management/RoleEdit.vue");

// 店家管理
const StoreAccountManagement = () => import("../views/store_management/StoreAccountManagement.vue");
const StoreAccountEdit = () => import("../views/store_management/StoreAccountEdit.vue");
const StoreManagementList = () => import("../views/store_management/StoreManagementList.vue")
const StoreManagementEdit = () => import("../views/store_management/StoreManagementEdit.vue");
const StoreProductManagementList = () => import("../views/store_management/StoreProductManagementList.vue")
const StoreProductManagementEdit = () => import("../views/store_management/StoreProductManagementEdit.vue");
const StorePromotionManagementList = () => import("../views/store_management/StorePromotionManagementList.vue")
const StorePromotionManagementEdit = () => import("../views/store_management/StorePromotionManagementEdit.vue")
const StoreTopRanking = () => import("../views/store_management/StoreTopRanking.vue");
const StoreTypeManagement = () => import("../views/store_management/StoreTypeManagement.vue");
const StoreTypeEdit = () => import("../views/store_management/StoreTypeEdit.vue");
const PointMultipleEdit = () => import("../views/store_management/PointMultipleEdit.vue");
const CouponList = () => import("../views/store_management/CouponList.vue")
const CouponEdit = () => import("../views/store_management/CouponEdit.vue");
// e指通特店專區
const TcpassStoreManagementList = () => import("../views/store_management/TcpassStoreManagementList.vue");
const TcpassStoreManagementEdit = () => import("../views/store_management/TcpassStoreManagementEdit.vue");

// 客服管理
const MessageList = () => import("../views/customer_sevice/MessageList.vue");
const ServiceList = () => import("../views/customer_sevice/ServiceList.vue");
const SaleStatistics = () => import("../views/customer_sevice/SaleStatistics.vue");
const ChannelManagement = () => import("../views/customer_sevice/ChannelManagement.vue");
const MessageDetail = () => import("../views/customer_sevice/MessageDetail.vue");
const FAQList = () => import("../views/customer_sevice/FAQList.vue");
const FAQDetail = () => import("../views/customer_sevice/FAQDetail.vue");
const Performance = () => import("../views/customer_sevice/Performance.vue");

// 店家資料管理《店家》
const StoreList = () => import("../views/store/StoreList.vue");
const StoreEdit = () => import("../views/store/StoreEdit.vue");
const StoreProductList = () => import("../views/store/StoreProductList.vue");
const StoreProductEdit = () => import("../views/store/StoreProductEdit.vue");
const StorePromotionList = () => import("../views/store/StorePromotionList.vue");
const StorePromotionEdit = () => import("../views/store/StorePromotionEdit.vue");
// e指通特店專區
const TcpassStoreList = () => import("../views/store/TcpassStoreList.vue");
const TcpassStoreEdit = () => import("../views/store/TcpassStoreEdit.vue");

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "",
                name: "Index",
                component: Index,
            },
            {
                path: "/AccountManagement",
                name: "AccountManagement",
                component: AccountManagement,
            },
            {
                path: "/AccountEdit",
                name: "AccountCreate",
                component: AccountEdit,
            },
            {
                path: "/AccountEdit/:id(\\d+)",
                name: "AccountUpdate",
                component: AccountEdit,
            },
            {
                path: "/AccountView/:id(\\d+)",
                name: "AccountView",
                component: AccountEdit,
            },
            {
                path: "/RoleManagement",
                name: "RoleManagement",
                component: RoleManagement,
            },
            {
                path: "/RoleEdit",
                name: "RoleCreate",
                component: RoleEdit,
            },
            {
                path: "/RoleEdit/:id(\\d+)",
                name: "RoleUpdate",
                component: RoleEdit,
            },
            {
                path: "/RoleView/:id(\\d+)",
                name: "RoleView",
                component: RoleEdit,
            },
            {
                path: "/StoreAccountManagement",
                name: "StoreAccountManagement",
                component: StoreAccountManagement,
            },
            {
                path: "/StoreAccountEdit/:id(\\d+)",
                name: "StoreAccountUpdate",
                component: StoreAccountEdit,
            },
            {
                path: "/StoreAccountView/:id(\\d+)",
                name: "StoreAccountView",
                component: StoreAccountEdit,
            },
            {
                path: "/StoreManagementList",
                name: "StoreManagementList",
                component: StoreManagementList,
            },
            {
                path: "StoreManagementEdit",
                name: "StoreManagementCreate",
                component: StoreManagementEdit,
            },
            {
                path: "StoreManagementEdit/:id(\\d+)",
                name: "StoreManagementUpdate",
                component: StoreManagementEdit,
            },
            {
                path: "StoreManagementView/:id(\\d+)",
                name: "StoreManagementView",
                component: StoreManagementEdit,
            },
            {
                path: "/StoreProductManagementList/:store_id(\\d+)",
                name: "StoreProductManagementList",
                component: StoreProductManagementList,
            },
            {
                path: "/StoreProductManagementEdit/:store_id(\\d+)",
                name: "StoreProductManagementCreate",
                component: StoreProductManagementEdit,
            },
            {
                path: "/StoreProductManagementEdit/:store_id(\\d+)/:id(\\d+)",
                name: "StoreProductManagementUpdate",
                component: StoreProductManagementEdit,
            },
            {
                path: "/StoreProductManagementView/:store_id(\\d+)/:id(\\d+)",
                name: "StoreProductManagementView",
                component: StoreProductManagementEdit,
            },
            {
                path: "/StorePromotionManagementList/:store_id(\\d+)",
                name: "StorePromotionManagementList",
                component: StorePromotionManagementList,
            },
            {
                path: "/StorePromotionManagementEdit/:store_id(\\d+)",
                name: "StorePromotionManagementCreate",
                component: StorePromotionManagementEdit,
            },
            {
                path: "/StorePromotionManagementEdit/:store_id(\\d+)/:id(\\d+)",
                name: "StorePromotionManagementUpdate",
                component: StorePromotionManagementEdit,
            },
            {
                path: "/StorePromotionManagementView/:store_id(\\d+)/:id(\\d+)",
                name: "StorePromotionManagementView",
                component: StorePromotionManagementEdit,
            },
            {
                path: "/StoreTopRanking",
                name: "StoreTopRanking",
                component: StoreTopRanking,
            },
            {
                path: "/StoreTypeManagement",
                name: "StoreTypeManagement",
                component: StoreTypeManagement,
            },
            {
                path: "/StoreTypeEdit",
                name: "StoreTypeCreate",
                component: StoreTypeEdit,
            },
            {
                path: "/StoreTypeEdit/:id(\\d+)",
                name: "StoreTypeUpdate",
                component: StoreTypeEdit,
            },
            {
                path: "/PointMultiple",
                name: "PointMultipleEdit",
                component: PointMultipleEdit,
            },
            {
                path: "/CouponList",
                name: "CouponList",
                component: CouponList,
            },
            {
                path: "CouponEdit",
                name: "CouponCreate",
                component: CouponEdit,
            },
            {
                path: "CouponEdit/:id(\\d+)",
                name: "CouponUpdate",
                component: CouponEdit,
            },
            {
                path: "CouponView/:id(\\d+)",
                name: "CouponView",
                component: CouponEdit,
            },
            {
                path: "/TcpassStoreManagementList",
                name: "TcpassStoreManagementList",
                component: TcpassStoreManagementList,
            },
            {
                path: "/TcpassStoreManagementView/:tcpass_store_id(\\d+)",
                name: "TcpassStoreManagementView",
                component: TcpassStoreManagementEdit,
            },
            {
                path: "/TcpassStoreManagementEdit/:tcpass_store_id(\\d+)",
                name: "TcpassStoreManagementEdit",
                component: TcpassStoreManagementEdit,
            },
            {
                path: "/MessageList",
                name: "MessageList",
                component: MessageList,
            },
            {
                path: "/ServiceList",
                name: "ServiceList",
                component: ServiceList,
            },
            {
                path: "/SaleStatistics",
                name: "SaleStatistics",
                component: SaleStatistics,
            },
            {
                path: "/ChannelManagement",
                name: "ChannelManagement",
                component: ChannelManagement,
            },
            {
                path: "/MessageDetail",
                name: "MessageDetailCreate",
                component: MessageDetail,
            },
            {
                path: "/MessageDetail/:id(\\d+)",
                name: "MessageDetailUpdate",
                component: MessageDetail,
            },
            {
                path: "MessageDetailView/:id(\\d+)",
                name: "MessageDetailView",
                component: MessageDetail,
            },
            {
                path: "/FAQList",
                name: "FAQList",
                component: FAQList,
            },
            {
                path: "/FAQDetail",
                name: "FAQDetailCreate",
                component: FAQDetail,
            },
            {
                path: "/FAQDetail/:id(\\d+)",
                name: "FAQDetailUpdate",
                component: FAQDetail,
            },
            {
                path: "/FAQDetailView/:id(\\d+)",
                name: "FAQDetailView",
                component: FAQDetail,
            },
            {
                path: "/Performance",
                name: "Performance",
                component: Performance,
            },
            {
                path: "/ModifyPassword",
                name: "ModifyPassword",
                component: ModifyPassword,
            },
            {
                path: "/StoreList",
                name: "StoreList",
                component: StoreList,
            },
            {
                path: "/StoreEdit",
                name: "StoreCreate",
                component: StoreEdit,
            },
            {
                path: "/StoreEdit/:id(\\d+)",
                name: "StoreUpdate",
                component: StoreEdit,
            },
            {
                path: "/StoreProductList/:store_id(\\d+)",
                name: "StoreProductList",
                component: StoreProductList,
            },
            {
                path: "/StoreProductEdit/:store_id(\\d+)",
                name: "StoreProductCreate",
                component: StoreProductEdit,
            },
            {
                path: "/StoreProductEdit/:store_id(\\d+)/:id(\\d+)",
                name: "StoreProductUpdate",
                component: StoreProductEdit,
            },
            {
                path: "/StorePromotionList/:store_id(\\d+)",
                name: "StorePromotionList",
                component: StorePromotionList,
            },
            {
                path: "/StorePromotionEdit/:store_id(\\d+)",
                name: "StorePromotionCreate",
                component: StorePromotionEdit,
            },
            {
                path: "/StorePromotionEdit/:store_id(\\d+)/:id(\\d+)",
                name: "StorePromotionUpdate",
                component: StorePromotionEdit,
            },
            {
                path: "/TcpassStoreList",
                name: "TcpassStoreList",
                component: TcpassStoreList,
            },
            {
                path: "/TcpassStoreEdit",
                name: "TcpassStoreCreate",
                component: TcpassStoreEdit,
            },
            {
                path: "/TcpassStoreEdit/:id(\\d+)",
                name: "TcpassStoreUpdate",
                component: TcpassStoreEdit,
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
    },
    {
        path: "/ForgetPassword",
        name: "ForgetPassword",
        component: ForgetPassword,
    },
    {
        path: "/Register",
        name: "Register",
        component: Register,
    },
    {
        path: "/ResendVerificationMail",
        name: "ResendVerificationMail",
        component: ResendVerificationMail,
    },
    {
        path: "/VerifyEmail",
        name: "VerifyEmail",
        component: VerifyEmail,
    },
    {
        path: "/ResetPassword",
        name: "ResetPassword",
        component: ResetPassword,
    },
    {
        path: '/:pathMatch(.*)', //重新導向
        name: 'NotFound',
        redirect: {
            name: 'Index'
        },
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
